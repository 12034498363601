import React from "react";
import TextLoop from "react-text-loop";

const conctInfo = {
  phone: "+1 832-278-2285",
  email: " contactus@grainsoflove.net",
};

const sliderContent = {
  name: "Love",
  designation: "Sand Artist",
  description: `Grains of Love offers uniquely wonderful sandwritten moments captured within an illuminated sandbox and blended into a beautifully edited video. From memorials of loved ones and pets to birthday and anniversary wishes, and even marriage proposals or gender reveals, Grains of Love delivers a one-of-a-kind keepsake that you'll cherish for years to come.`,
  btnText: " Place an Order",
};

const Slider = () => {
  return (
    <>
      {/*  Home Banner */}
      <section id="home" className="home-banner">
        <div className="hb-top-fixed d-flex">
          <div className="hb-info">
            <a href="tel:+04 6545-9535-6515">{conctInfo.phone}</a>
            <a href="mailto:contactus@grainsoflove.net">
              {conctInfo.email}
            </a>
          </div>
        </div>
        {/* End hp-top-fixed */}

        <div className="container">
          <div className="row full-screen align-items-center">
            <div className="col-lg-6">
              <div className="type-box">
                <h6 data-aos="fade-up" data-aos-duration="1200">
                  WELCOME TO
                </h6>
                <h2 data-aos="fade-up" data-aos-duration="1200">
                  Grains of
                </h2>
                <h1
                  className="font-alt"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                  data-aos-delay="100"
                >
                  {sliderContent.name}
                </h1>
                <div
                  data-aos="fade-up"
                  data-aos-duration="1200"
                  data-aos-delay="200"
                >
                  <p className="loop-text lead">By Patti Broussard</p>
                </div>

                <p
                  className="desc"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                  data-aos-delay="300"
                >
                  {sliderContent.description}
                </p>
                <div
                  className="mt-4"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                  data-aos-delay="400"
                >
                  <a className="px-btn px-btn-white" href="#services">{sliderContent.btnText}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Container*/}
        <div
          className="hb-me"
          style={{
            backgroundImage: `url(${
              process.env.PUBLIC_URL + "img/slider/home-banner.png"
            })`,
          }}
        ></div>
      </section>

      {/* End Home Banner  */}
    </>
  );
};

export default Slider;
