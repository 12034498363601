import React from "react";
import Social from "../Social";

const About = () => {
  return (
    <>
      <section id="about" className="section theme-light dark-bg">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div
              className="col-md-6 col-lg-4"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <div className="about-me">
                <div className="img">
                  <div className="img-in">
                    <img src="img/about/about-me.png" alt="about" />
                  </div>

                  <Social />

                  {/* End social icon */}
                </div>
                {/* End img */}
                <div className="info">
                  <p>Sand Artist</p>
                  <h3>Patti Broussard</h3>
                </div>
                {/* End info */}
              </div>
              {/* End about-me */}
            </div>
            {/* End col */}

            <div
              className="col-lg-7 ml-auto"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <div className="about-info">
                <div className="title">
                  <h3>Biography</h3>
                </div>
                <div className="about-text">
                  <p>
                  Patti Broussard, the creator of Grains of Love Sandwritten moments, has an extensive background in the creative arts. She is a musician, accomplished on many instruments, with a love for sharing her very unusual instrument, the Array Mbira. She currently has more than 400,000 followers on TikTok who love hearing her music.
Patti has a background in ballet, tap and lyrical dance and was a dance instructor for almost 30 years.
She also is an accomplished puppeteer, specializing in Black Light Theater and has created custom puppets for professional use.
                  </p>
                  <p>
                  Patti discovered her love for sand art in 2014 and took to the art like a duck to water. 
Patti currently has a following of almost 1/2 Million followers on TikTok, loving what she creates in the sand. 
And she wants to create for you! Beautifully edited sandwritten moments.
                  </p>
                </div>
                
              </div>
            </div>
            {/* End col */}
          </div>

          {/* separated */}

          {/* End .row */}
        </div>
      </section>
    </>
  );
};

export default About;
